import { get, filter, find, isEqual, isNumber } from 'lodash';
import { createSelectorCreator, defaultMemoize } from 'reselect';

import {
  AUTHOR_INFO_TYPE_MOBILE_PATH,
  AUTHOR_INFO_TYPE_NAME_PICTURE,
  AUTHOR_INFO_TYPE_PATH,
  CARD_BORDER_WIDTH_PATH,
  getGenericPostFontPath,
  getHeaderTextAlignmentPath,
  getIsSocialSharingProviderEnabledParamPath,
  getPostFontSizesPath,
  getSBSLayoutImagePositionParamPath,
  getLayoutPostSizeParamPath,
  getLayoutSpacingParamPath,
  getLayoutContentHeightParamPath,
  getLayoutImageRatioParamPath,
  getLayoutSidesPaddingParamPath,
  getLayoutMarginsParamPath,
  getLayoutImageProportionsParamPath,
  getLayoutImageAlignmentParamPath,
  getFeedBorderColorPath,
  HEADER_DEPRECTED_PATH,
  HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH,
  HEADER_SHOW_ON_CATEGORY_PAGE_PATH,
  IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
  IS_AUTHOR_NAME_ENABLED_PATH,
  IS_BANNER_ENABLED_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
  IS_BLOG_MENU_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_ENABLED_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
  IS_BLOG_MENU_SEARCH_ENABLED_PATH,
  IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
  IS_CATEGORY_LABELS_ENABLED_PATH,
  IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
  IS_COMMENT_COUNT_ENABLED_PATH,
  IS_COMMENTS_ENABLED_MOBILE_PATH,
  IS_COMMENTS_ENABLED_PATH,
  IS_FEED_DESIGN_PROPS_MIGRATED_PATH,
  IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
  IS_LIKE_COUNT_ENABLED_PATH,
  IS_MOBILE_DIPSLAY_SETTINGS_ENABLED_PATH,
  IS_PINTEREST_ENABLED_PATH,
  IS_POST_DATE_ENABLED_MOBILE_PATH,
  IS_POST_DATE_ENABLED_PATH,
  IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
  IS_POST_DESCRIPTION_ENABLED_PATH,
  IS_POST_LIST_FULL_WIDTH_ENABLED_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
  IS_POST_PUBLISH_DATE_ENABLED_PATH,
  IS_POST_TITLE_ENABLED_MOBILE_PATH,
  IS_POST_TITLE_ENABLED_PATH,
  IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
  IS_POST_UPDATED_DATE_ENABLED_PATH,
  IS_READING_TIME_ENABLED_MOBILE_PATH,
  IS_READING_TIME_ENABLED_PATH,
  IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
  IS_RECENT_POSTS_ENABLED_PATH,
  IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
  IS_SOCIAL_SHARING_ENABLED_PATH,
  IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
  IS_VIEW_COUNT_ENABLED_PATH,
  POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
  POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
  POST_PAGE_FONT_SIZE_MOBILE_PATH,
  POST_QUOTES_FONT_SIZE_MOBILE_PATH,
  POST_TITLE_FONT_SIZE_MOBILE_PATH,
  SECTION_CATEGORY,
  SECTION_HOMEPAGE,
  IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
  IS_AUTHOR_PICTURE_ENABLED_PATH,
  IS_AUTHOR_BADGE_ENABLED_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
  IS_MORE_OPTIONS_MENU_ENABLED_PATH,
  IS_TAGS_ENABLED_PATH,
  IS_TAGS_ENABLED_MOBILE_PATH,
  getLayoutImageCropTypeParamPath,
  SECTION_POST_LIST,
  IS_TAG_LABEL_ENABLED_PATH,
  TAG_LAYOUT_PATH,
  DEFAULT_TAG_LAYOUT,
  TAG_DISPLAY_PARAMS,
  getTagLayoutParams,
  getLayoutTypeById,
  SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  isLayoutNamePGSideBySide,
  isLayoutNamePGOneColumn,
  IS_BANNER_ENABLED_DEFAULT,
  getDefaultFontSize,
  DEFAULT_PG_LAYOUT_IMAGE_RATIO,
  DEFAULT_PG_LAYOUT_ITEM_SIZE,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
  DEFAULT_PG_LAYOUT_SPACNG,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
  DEFAULT_PG_LAYOUT_MARGINS,
  DEFAULT_PG_IMAGE_ALIGNMENT,
  DEFAULT_PG_IMAGE_PROPORTIONS,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
  getPGLayoutSettingsConstant,
  TEXT_ALIGNMENT_DEFAULT_HOMEPAGE,
  TEXT_ALIGNMENT_DEFAULT_CATEGORY,
  isExperimentEnabled,
  SOCIAL_SHARING_PROVIDERS,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_HEADER_DESIGN } from '@wix/communities-blog-experiments';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import { getIsMobile } from '../store/basic-params/basic-params-selectors';
import {
  DEFAULT_POST_PAGE_FONT_SIZE_MOBILE,
  DEFAULT_POST_TITLE_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  DEFAULT_POST_QUOTES_SIZE_MOBILE,
} from '../../post-page/constants/post-page';
import { getLayoutType } from './layout-selectors';

import { getSection } from './section-selectors';
import { getAppSettings, getAppSettingsValue } from './app-settings-base-selectors';

const NOT_DEFINED = 'undefined_';
export const isDefinedAppSettingsValue = (state, key) =>
  getAppSettingsValue({ state, key, fallback: NOT_DEFINED }) !== NOT_DEFINED;

const isEnabledAppSettingsValue = (state, key) => getAppSettingsValue({ state, key, fallback: false }) === true;

export const getAppSettingsPresets = state => state.appSettingsPresets;

export const getStyle = state => getAppSettingsValue({ state, key: 'style', fallback: {} });

export const getCustomAppSettings = state => getAppSettingsValue({ state, key: 'custom', fallback: {} });

export const getLastUpdatedDate = state =>
  getAppSettingsValue({ state, key: 'updatedAt', fallback: new Date().getTime() });

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getColorPresets = createDeepEqualSelector([getAppSettings], appSettings => get(appSettings, 'colorPresets', []));

export const getSiteColors = createDeepEqualSelector([getColorPresets], colorPresets =>
  (colorPresets || []).reduce((acc, { reference, value }) => {
    acc[reference] = value;
    return acc;
  }, {}),
);

export const getIsMobileDisplaySettingsEnabled = state =>
  getAppSettingsValue({ state, key: IS_MOBILE_DIPSLAY_SETTINGS_ENABLED_PATH, fallback: false });

const getUseMobileSettings = state => getIsMobile(state) && getIsMobileDisplaySettingsEnabled(state);

const getDisplayToggle = ({ state, key, mobileKey, fallback }) =>
  getAppSettingsValue({
    state,
    key: getUseMobileSettings(state) ? mobileKey : key,
    fallback,
  });

export const getIsCommentCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENT_COUNT_ENABLED_MOBILE_PATH,
    key: IS_COMMENT_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsLikeCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_LIKE_COUNT_ENABLED_MOBILE_PATH,
    key: IS_LIKE_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsCategoryLabelsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

export const getIsTagsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_TAGS_ENABLED_MOBILE_PATH,
    key: IS_TAGS_ENABLED_PATH,
    fallback: true,
  });

export const getIsMoreButtonEnabled = (state, showMoreButton) => getIsMoreOptionsMenuEnabled(state) && showMoreButton;

export const getIsPostTitleEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_TITLE_ENABLED_MOBILE_PATH,
    key: IS_POST_TITLE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostDescriptionEnabled = (state, section) => {
  const mobileDisplaySettingsEnabled = getIsMobileDisplaySettingsEnabled(state);
  const isMobile = getIsMobile(state);

  if (section !== SECTION_POST_LIST && !mobileDisplaySettingsEnabled && isMobile) {
    return true;
  }

  return getDisplayToggle({
    state,
    mobileKey: IS_POST_DESCRIPTION_ENABLED_MOBILE_PATH,
    key: IS_POST_DESCRIPTION_ENABLED_PATH,
    fallback: true,
  });
};

export const getIsMoreOptionsMenuEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_MORE_OPTIONS_MENU_ENABLED_MOBILE_PATH,
    key: IS_MORE_OPTIONS_MENU_ENABLED_PATH,
    fallback: true,
  });

export const getIsViewCountEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_VIEW_COUNT_ENABLED_MOBILE_PATH,
    key: IS_VIEW_COUNT_ENABLED_PATH,
    fallback: true,
  });

export const getIsAuthorNameEnabled = state => {
  const useMobileSettings = getUseMobileSettings(state);
  return getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_NAME_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_NAME_ENABLED_PATH,
    fallback: useMobileSettings
      ? getAppSettingsValue({ state, key: IS_AUTHOR_NAME_ENABLED_PATH, fallback: true })
      : true,
  });
};

export const getIsAuthorPictureEnabled = (state, fallback = true) =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_PICTURE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_PICTURE_ENABLED_PATH,
    fallback,
  });

export const getIsAuthorBadgeEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_AUTHOR_BADGE_ENABLED_MOBILE_PATH,
    key: IS_AUTHOR_BADGE_ENABLED_PATH,
    fallback: getUseMobileSettings(state) ? getIsAuthorNameEnabled(state) : true,
  });

export const getAuthorInfoType = state =>
  getDisplayToggle({
    state,
    key: AUTHOR_INFO_TYPE_PATH,
    mobileKey: AUTHOR_INFO_TYPE_MOBILE_PATH,
    fallback: getUseMobileSettings(state)
      ? getAppSettingsValue({ state, key: AUTHOR_INFO_TYPE_PATH, fallback: AUTHOR_INFO_TYPE_NAME_PICTURE })
      : AUTHOR_INFO_TYPE_NAME_PICTURE,
  });

export const getIsPostDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_DATE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostUpdatedDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_UPDATED_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_UPDATED_DATE_ENABLED_PATH,
    fallback: true,
  });

export const getIsPostListFullWidthEnabled = state =>
  getAppSettingsValue({ state, key: IS_POST_LIST_FULL_WIDTH_ENABLED_PATH, fallback: false });

export const getIsFeedDesignPropsMigrated = state =>
  getAppSettingsValue({ state, key: IS_FEED_DESIGN_PROPS_MIGRATED_PATH, fallback: false });

export const getIsPostPublishDateEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_POST_PUBLISH_DATE_ENABLED_MOBILE_PATH,
    key: IS_POST_PUBLISH_DATE_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  });

export const getIsReadingTimeEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_READING_TIME_ENABLED_MOBILE_PATH,
    key: IS_READING_TIME_ENABLED_PATH,
    fallback: getIsPostDateEnabled(state),
  });

export const isHeaderDeprecated = state => getAppSettingsValue({ state, key: HEADER_DEPRECTED_PATH, fallback: false });

export const getIsHeaderDesignEnabled = state =>
  isExperimentEnabled(state, EXPERIMENT_HEADER_DESIGN) ||
  isEnabledAppSettingsValue(state, HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH) ||
  isEnabledAppSettingsValue(state, HEADER_SHOW_ON_CATEGORY_PAGE_PATH) ||
  (isHeaderDeprecated(state) !== true &&
    (isDefinedAppSettingsValue(state, HEADER_SHOW_ON_BLOG_HOMEPAGE_PATH) ||
      isDefinedAppSettingsValue(state, HEADER_SHOW_ON_CATEGORY_PAGE_PATH)));

export const getHeaderTextAlignment = (state, section) =>
  getAppSettingsValue({
    state,
    key: getHeaderTextAlignmentPath(section),
    fallback: section === SECTION_HOMEPAGE ? TEXT_ALIGNMENT_DEFAULT_HOMEPAGE : TEXT_ALIGNMENT_DEFAULT_CATEGORY,
  });

export const getCardBorderWidth = state => getAppSettingsValue({ state, key: CARD_BORDER_WIDTH_PATH, fallback: 1 });

export const getIsBlogMenuEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuCategoryLabelsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_CATEGORY_LABELS_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuSearchEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_SEARCH_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_SEARCH_ENABLED_PATH,
    fallback: true,
  });

export const getIsBlogMenuLoginButtonEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_MOBILE_PATH,
    key: IS_BLOG_MENU_LOGIN_BUTTON_ENABLED_PATH,
    fallback: !getIsMemberAreaInstalled(state),
  });

export const getIsRecentPostsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_RECENT_POSTS_ENABLED_MOBILE_PATH,
    key: IS_RECENT_POSTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsCommentsEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_COMMENTS_ENABLED_MOBILE_PATH,
    key: IS_COMMENTS_ENABLED_PATH,
    fallback: true,
  });

export const getIsSocialSharingEnabled = state =>
  getDisplayToggle({
    state,
    mobileKey: IS_SOCIAL_SHARING_ENABLED_MOBILE_PATH,
    key: IS_SOCIAL_SHARING_ENABLED_PATH,
    fallback: true,
  });

export const getIsSocialSharingProviderEnabled = (state, provider) =>
  getAppSettingsValue({
    state,
    key: getIsSocialSharingProviderEnabledParamPath(provider, getUseMobileSettings(state)),
    fallback: true,
  });

export const getSocialSharingProviders = state =>
  getIsSocialSharingEnabled(state)
    ? filter(SOCIAL_SHARING_PROVIDERS, provider => getIsSocialSharingProviderEnabled(state, provider))
    : [];

export const getPostTitleFontSizeMobile = state =>
  getAppSettingsValue({ state, key: POST_TITLE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_TITLE_FONT_SIZE_MOBILE });
export const getPostPageFontSizeMobile = state =>
  getAppSettingsValue({ state, key: POST_PAGE_FONT_SIZE_MOBILE_PATH, fallback: DEFAULT_POST_PAGE_FONT_SIZE_MOBILE });
export const getPostHeaderTwoFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_TWO_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_TWO_FONT_SIZE_MOBILE,
  });
export const getPostHeaderThreeFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_HEADER_THREE_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_HEADER_THREE_SIZE_MOBILE,
  });
export const getPostQuotesFontSizeMobile = state =>
  getAppSettingsValue({
    state,
    key: POST_QUOTES_FONT_SIZE_MOBILE_PATH,
    fallback: DEFAULT_POST_QUOTES_SIZE_MOBILE,
  });

const getDesktopFontSize = (state, section, font) =>
  getAppSettingsValue({ state, key: `${getGenericPostFontPath(section, font)}.size` });

export const getFontSize = (state, layoutType, section, contentType) => {
  const stylePath = getPostFontSizesPath(layoutType, section || SECTION_CATEGORY, `${contentType}FontSize`);
  const defaultSize = getDefaultFontSize(layoutType, contentType);
  const desktopFontSize = getIsMobile(state) ? getDesktopFontSize(state, section, `${contentType}Font`) : undefined;
  return get(getAppSettings(state), stylePath, desktopFontSize || defaultSize);
};

export const getPinterestEnabled = state =>
  getAppSettingsValue({
    state,
    key: IS_PINTEREST_ENABLED_PATH,
    fallback: true,
  });

export const getSBSLayoutImagePosition = (state, section) =>
  getAppSettingsValue({
    state,
    key: getSBSLayoutImagePositionParamPath(section),
    fallback: SIDE_BY_SIDE_LAYOUT_IMAGE_RIGHT,
  });

export const getIsFeaturedPostBannerEnabled = state =>
  getAppSettingsValue({ state, key: IS_BANNER_ENABLED_PATH, fallback: IS_BANNER_ENABLED_DEFAULT });

export const getIsCreatedWithResponsiveEditor = state => get(getAppSettings(state), 'style.booleans.responsive', false);

const PG_SIDE_BY_SIDE_LAYOUT_SIZE = 1100;
const PG_ONE_COLUMN_LAYOUT_SIZE = 980;

export const getLayoutPostSize = ({ state, section, hostWidth, rootWidth, layoutMargins, layoutType }) => {
  section = section || getSection(state);

  layoutType = layoutType || getLayoutType(state, section);
  const layoutName = getLayoutTypeById(layoutType);

  const layoutPGSideBySide = isLayoutNamePGSideBySide(layoutName);
  const layoutPGOneColumn = isLayoutNamePGOneColumn(layoutName);

  if (layoutPGSideBySide || layoutPGOneColumn) {
    const layoutSize = layoutPGSideBySide ? PG_SIDE_BY_SIDE_LAYOUT_SIZE : PG_ONE_COLUMN_LAYOUT_SIZE;
    const width = isNumber(hostWidth) ? hostWidth : isNumber(rootWidth) ? rootWidth : null;
    const layoutPostSize = width !== null && width < layoutSize ? width : layoutSize;
    if (layoutMargins) {
      return layoutPostSize - layoutMargins * 2;
    }
    return layoutPostSize;
  }

  return getAppSettingsValue({
    state,
    key: getLayoutPostSizeParamPath(section, layoutName),
    fallback: getPGLayoutSettingsConstant(layoutType, DEFAULT_PG_LAYOUT_ITEM_SIZE),
  });
};

const getPGLayoutAppSetting = (paramPathResolver, fallBackSetting) => ({ state, section, layoutType }) => {
  section = section || getSection(state);

  layoutType = layoutType || getLayoutType(state, section);
  const layoutName = getLayoutTypeById(layoutType);

  return getAppSettingsValue({
    state,
    key: paramPathResolver(section, layoutName),
    fallback: getPGLayoutSettingsConstant(layoutType, fallBackSetting),
  });
};

export const getLayoutSpacing = getPGLayoutAppSetting(getLayoutSpacingParamPath, DEFAULT_PG_LAYOUT_SPACNG);

export const getLayoutImageRatio = getPGLayoutAppSetting(getLayoutImageRatioParamPath, DEFAULT_PG_LAYOUT_IMAGE_RATIO);

export const getLayoutImageAlignment = getPGLayoutAppSetting(
  getLayoutImageAlignmentParamPath,
  DEFAULT_PG_IMAGE_ALIGNMENT,
);

export const getLayoutContentHeight = getPGLayoutAppSetting(
  getLayoutContentHeightParamPath,
  DEFAULT_PG_LAYOUT_INFO_SIZE,
);

export const getLayoutMargins = getPGLayoutAppSetting(getLayoutMarginsParamPath, DEFAULT_PG_LAYOUT_MARGINS);

export const getLayoutSidesPadding = getPGLayoutAppSetting(
  getLayoutSidesPaddingParamPath,
  DEFAULT_PG_LAYOUT_SIDES_PADDING,
);

export const getLayoutImageCropType = getPGLayoutAppSetting(
  getLayoutImageCropTypeParamPath,
  DEFAULT_PG_LAYOUT_IMAGE_CROP_TYPE,
);

export const getLayoutImageProportions = getPGLayoutAppSetting(
  getLayoutImageProportionsParamPath,
  DEFAULT_PG_IMAGE_PROPORTIONS,
);

export const getPresetColor = (state, presetName) =>
  get(
    find(getColorPresets(state), ({ reference }) => reference === presetName),
    'value',
  );

export const getFeedBorderColor = ({ state, section }) =>
  getAppSettingsValue({
    state,
    key: getFeedBorderColorPath(section),
    fallback: { value: getPresetColor(state, 'color-2') },
  });

export const getIsTagLabelEnabled = state =>
  getAppSettingsValue({
    state,
    key: IS_TAG_LABEL_ENABLED_PATH,
    fallback: false,
  });

export const getTagLayout = state =>
  getAppSettingsValue({
    state,
    key: TAG_LAYOUT_PATH,
    fallback: DEFAULT_TAG_LAYOUT,
  });

export const getTagAlignment = state => {
  const layoutParams = getTagLayoutParams(getTagLayout(state));

  return getAppSettingsValue({
    state,
    key: layoutParams.tagAlignment.appSettingsPath,
    fallback: layoutParams.tagAlignment.defaultAlignment,
  });
};

export const getTagVerticalSpacing = state => {
  const layoutParams = getTagLayoutParams(getTagLayout(state));

  return getAppSettingsValue({
    state,
    key: layoutParams.verticalSpacing.appSettingsPath,
    fallback: layoutParams.verticalSpacing.defaultSpacing,
  });
};

export const getTagHorizontalSpacing = state => {
  const layoutParams = getTagLayoutParams(getTagLayout(state));

  return getAppSettingsValue({
    state,
    key: layoutParams.horizontalSpacing.appSettingsPath,
    fallback: layoutParams.horizontalSpacing.defaultSpacing,
  });
};

export const getNumberOfTags = state =>
  getAppSettingsValue({
    state,
    key: TAG_DISPLAY_PARAMS.numberOfTags.appSettingsPath,
    fallback: TAG_DISPLAY_PARAMS.numberOfTags.defaultValue,
  });

export const getTagOrder = state =>
  getAppSettingsValue({
    state,
    key: TAG_DISPLAY_PARAMS.orderTags.appSettingsPath,
    fallback: TAG_DISPLAY_PARAMS.orderTags.defaultValue,
  });

export const getTagShowPostCount = state =>
  getAppSettingsValue({
    state,
    key: TAG_DISPLAY_PARAMS.showPostCount.appSettingsPath,
    fallback: TAG_DISPLAY_PARAMS.showPostCount.defaultValue,
  });
